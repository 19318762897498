(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:ItemListCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('ItemListCtrl', ItemListCtrl);

  function ItemListCtrl(FormControl,
                        $mdDialog,
                        $document,
                        orderType,
                        Cart,
                        _,
                        $q,
                        $scope,
                        $rootScope,
                        $translate,
                        isAuthenticated,
                        OrderService,
                        user,
                        wallets) {
    var vm = this
      , formControl = new FormControl()
      , cart = new Cart()
      , OrderItem = cart.getOrderItem()
      , deferred = $q.defer()
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType';

    cart.id = orderType;
    OrderItem = cart.getOrderItem();
    vm.order = {};
    vm.orderType = orderType;
    vm.wallets = wallets;
    vm.selectedProductType = localStorage.getItem(EdenredECSselectedProductType);

    vm.itemListView = (orderType === '1') ?
      'wb-order/views/new-card-item-list.tpl.html' : 'wb-order/views/topup-item-list.tpl.html';

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.findWalletById = function findWalletById(id) {
      return _.find(vm.wallets, {id: id});
    };

    vm.refreshOrderItemList = function refreshOrderItemList() {
      vm.order.items = OrderItem.query();
      OrderService.updateCart();
    };

    vm.refreshOrderItemList();

    $rootScope.$on('itemList.refresh', vm.refreshOrderItemList);

    $rootScope.$on('itemList.openItemDialog', function (e, item) {
      vm.openItemDialog(undefined, item);
    });

    vm.openItemDialog = function openItemDialog(index, rawItem) {
      var item = !_.isUndefined(rawItem) ? new OrderItem(rawItem) : _.defaultsDeep(new OrderItem(), vm.order.items[index]);
      $mdDialog.show({
        controller: 'ItemDialogCtrl',
        controllerAs: 'itemDialog',
        templateUrl: 'wb-order/views/item-dialog.tpl.html',
        bindToController: true,
        locals: {
          item: item,
          orderType: orderType,
          user: user,
        },
        parent: angular.element($document.body),
        clickOutsideToClose: true,
        focusOnOpen: false
      });
    };

    vm.editItem = function editItem(index) {
      vm.openItemDialog(index);
    };

    vm.deleteItem = function deleteItem(index) {
      var confirm = $mdDialog.confirm({focusOnOpen: false})
        .parent(angular.element($document.body))
        .content($translate.instant('order.itemDialog.confirmDelete'))
        .ok($translate.instant('order.itemDialog.delete'))
        .cancel($translate.instant('order.itemDialog.cancel'));

      $mdDialog
        .show(confirm)
        .then(function () {
          var orderItem = _.defaultsDeep(new OrderItem(), vm.order.items[index]);

          deferred = $q.defer();

          formControl.setSuccessText('order.unique.toast.delete');
          formControl
            .process(deferred.promise)
            .then(function () {
              $rootScope.$broadcast('itemList.refresh');
            });
          orderItem.$delete(deferred.resolve, deferred.reject);
        });
    };
  }
}());
